import React from 'react';
// import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { object, string } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  data: object.isRequired,
  url: string.isRequired,
};

const NewsItem = (props) => {
  const { sitecoreContext, data, url } = props;

  // Update Months in Arabic
  if (sitecoreContext.language === 'ar') {
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }

  return (
    <div className="news-item">
      <div className="news-item__image">
        <Link
          to={url
            .replace('/sitecore/content/ksp/home', `/${sitecoreContext.language}`)
            .toLowerCase()}
        >
          <img src={data.Image__Url} alt={data.Image__Alt} />
        </Link>
      </div>
      <div className="news-item__date">{moment(data.Date).format('DD MMMM YYYY')}</div>
      <h3 className="news-item__title">
        <Link
          to={url
            .replace('/sitecore/content/ksp/home', `/${sitecoreContext.language}`)
            .toLowerCase()}
        >
          {data.Title}
        </Link>
      </h3>
      <p>{data.Description}</p>
    </div>
  );
};

NewsItem.propTypes = propTypes;

export default withSitecoreContext()(NewsItem);
