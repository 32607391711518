import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object } from 'prop-types';

const propTypes = {
  rendering: object.isRequired,
};

const TwoColumnGrid = ({ rendering }) => {
  return (
    <div className="container">
      <div className="contact-modal">
        <div className="contact-modal__container-form">
          <Placeholder name="left-col" rendering={rendering} />
        </div>
        <div className="contact-modal__container-us">
          <div className="contact-modal__container-us-center">
            <Placeholder name="right-col" rendering={rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

TwoColumnGrid.propTypes = propTypes;

export default TwoColumnGrid;
