import React from 'react';
import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import { withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';

const SitecoreForm = ({ fields, history }) => {
  return (
    <Form
      form={fields}
      sitecoreApiHost={sitecoreApiHost}
      sitecoreApiKey={sitecoreApiKey}
      onRedirect={(url) => history.push(url)}
    />
  );
};

export default withRouter(SitecoreForm);
