import React from 'react';
// import { Text } from '@sitecore-jss/sitecore-jss-react';

const Spinner = (props) => (
  <div className="spinner">
    <div className="spin">Loading...</div>
  </div>
);

export default Spinner;
