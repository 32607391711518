import React from 'react';
import { RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { object, shape } from 'prop-types';

const propTypes = {
  fields: shape({
    contentImage: object.isRequired,
    description: object.isRequired,
    contentIcon: object.isRequired,
  }),
};

const ContentHero = ({ fields }) => (
  <div className="content-hero">
    <Image className="content-hero__bg" field={fields.contentImage} />
    <div className="content-hero__desc">
      <div>
        <RichText
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="1000"
          data-aos-duration="1000"
          field={fields.description}
        />
        <Image
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="750"
          data-aos-duration="1000"
          className="content-hero__desc--icon"
          media={fields.contentIcon}
        />
      </div>
    </div>
  </div>
);

ContentHero.propTypes = propTypes;

export default ContentHero;
