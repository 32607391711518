import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { object } from 'prop-types';

const propTypes = {
  fields: object.isRequired,
};

const StaticMapPark = ({ fields }) => (
  <div className="static-map">
    <Image className="static-map__pic" media={fields.mapPark} />
  </div>
);

StaticMapPark.propTypes = propTypes;

export default StaticMapPark;
