import React from 'react';
// import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { object, string } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  data: object.isRequired,
  url: string.isRequired,
};

const RelatedNewsItem = (props) => {
  const { sitecoreContext, data, url } = props;

  // Update Months in Arabic
  if (sitecoreContext.language === 'ar') {
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }

  return (
    <div className="news-item">
      <div className="news-item__image">
        <Link to={`/${sitecoreContext.language}${url}`}>
          <img src={data.Image.value.src} alt={data.Image.value.alt} />
        </Link>
      </div>
      <div className="news-item__date">{moment(data.Date.value).format('DD MMMM YYYY')}</div>
      <h3 className="news-item__title">
        <Link to={`/${sitecoreContext.language}${url}`}>{data.Title.value}</Link>
      </h3>
      <p>{data.Description.value}</p>
    </div>
  );
};

RelatedNewsItem.propTypes = propTypes;

export default withSitecoreContext()(RelatedNewsItem);
