import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { object } from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const propTypes = {
  sitecoreContext: object.isRequired,
};

const InnerPageContent = ({ sitecoreContext }) => (
  <>
    <div className="inner-page__top">
      <div className="inner-page__top--photo">
        <Image media={sitecoreContext.route.fields.heroImage} />
      </div>
      <div className="container">
        <div className="inner-page__top--content">
          <Text tag="h1" field={sitecoreContext.route.fields.title} />
        </div>
      </div>
    </div>
    <div className="container">
      <div className="inner-page__content">
        <RichText field={sitecoreContext.route.fields.body} />
      </div>
    </div>
  </>
);

InnerPageContent.propTypes = propTypes;

export default withSitecoreContext()(InnerPageContent);
