import { useEffect, useState } from 'react';

export const useNewsFetch = (config, lang) => {
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [latestNews, setLatestNews] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const fetchNews = async (skip) => {
    try {
      setLoading(true);
      let skipNews = skip === 0 ? '' : `&$skip=${skip}`;
      let url =
        config.sitecoreApiHost.indexOf('localhost') === -1
          ? `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${lang}&$filter=TemplateName eq 'News Article'&$orderby=FieldValues/Date desc&$expand=FieldValues${skipNews}&$top=9`
          : `https://ksp-528790-single.azurewebsites.net/sitecore/api/ssc/aggregate/content/Items?sc_apikey={23826AF0-2BC5-4DFA-B6D9-1BA0E12F3DF4}&language=${lang}&$filter=TemplateName eq 'News Article'&$orderby=FieldValues/Date desc&$expand=FieldValues${skipNews}&$top=9`;

      const request = await fetch(url);

      let result = await request.json();
      setLatestNews((prev) => (skip > 0 ? [...prev, ...result.value] : [...result.value]));
    } catch (error) {
      // setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchNews(skip);
  }, []);

  // Load More
  useEffect(() => {
    if (!isLoadingMore) return;
    fetchNews(skip + 9);
    setSkip(skip + 9);
    setIsLoadingMore(false);
  }, [isLoadingMore, skip]);

  return {
    latestNews,
    loading,
    setIsLoadingMore,
  };
};
