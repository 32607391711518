import React, { useState } from 'react';
import useMatchMedia from '../../hooks/useMatchMedia';
import { Image, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { object, shape, string } from 'prop-types';

const propTypes = {
  slide: shape({
    title: object.isRequired,
    image: object.isRequired,
    description: object.isRequired,
    shape: object,
    highlightsText: object,
    highlightsShape: object,
    highlightsRecord: object,
  }).isRequired,
  slideType: string.isRequired,
  variant: string.isRequired,
};

const SlideshowCarouselItem = ({ slide, slideType, variant }) => {
  // matches bp-large-min in breakpoints.scss
  const isDesktop = useMatchMedia('(min-width: 1024px)');

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = (collapse) => {
    setCollapsed(collapse);
  };

  // Variant Cards
  const renderCards = (
    <div className="slideshow-carousel__slide">
      <div className="slideshow-carousel__slide-image">
        <Image media={slide.image} />
      </div>
      <div className="slideshow-carousel__slide-content">
        {slideType === 'CardlessCarouselItem' ? (
          <div className="slideshow-carousel__slide-cardless">
            <div className="slideshow-carousel__slide-cardless--content">
              <Image media={slide.shape} />
              <Text tag="h2" field={slide.title} />
            </div>
          </div>
        ) : (
          <>
            <div className="container">
              <div className={`slideshow-carousel__slide-card ${collapsed && 'card-collapsed'}`}>
                <div className="slideshow-carousel__slide-card--header">
                  <Text tag="h2" field={slide.title} />
                  <button
                    className="btn btn-collapse"
                    onClick={() => {
                      handleCollapse(!collapsed);
                    }}
                  >
                    {collapsed ? plusIcon : minusIcon}
                  </button>
                </div>
                <div className="slideshow-carousel__slide-card--content">
                  <div className="slideshow-carousel__slide-card--content-inner">
                    <div>
                      <RichText field={slide.description} />
                    </div>
                    {(slide.highlightsText.value ||
                      slide.highlightsShape.value ||
                      slide.highlightsRecord.value) && (
                      <div
                        className="slideshow-carousel__slide-card--highlights"
                        style={{ backgroundImage: `url(${slide.highlightsShape.value.src})` }}
                      >
                        <Text tag="h3" field={slide.highlightsRecord} />
                        <Text tag="span" field={slide.highlightsText} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  // Variant Full Cards - Mobile
  const renderFullCardsMobile = (
    <div className="slideshow-carousel__slide">
      <div className="slideshow-carousel__slide-content">
        <div className="container">
          <div className="slideshow-carousel__slide-content--inner">
            <div className="slideshow-carousel__slide-card--header">
              <Text tag="h2" field={slide.title} />
            </div>
            <div className="slideshow-carousel__slide-image--mobile">
              <Image media={slide.image} />
            </div>
            <div className="slideshow-carousel__slide-card--content">
              <div className="slideshow-carousel__slide-card--content-inner">
                <div>
                  <RichText field={slide.description} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Variant Full Cards - Desktop
  const renderFullCardsDesktop = (
    <div className="slideshow-carousel__slide">
      <div className={`slideshow-carousel__slide-image ${collapsed && 'card-collapsed'}`}>
        <Image media={slide.image} />
      </div>
      <div className="slideshow-carousel__slide-content">
        <div className={`slideshow-carousel__slide-card ${collapsed && 'card-collapsed'}`}>
          <div className="container">
            <div className="slideshow-carousel__slide-content--inner">
              <div className="slideshow-carousel__slide-card--header">
                <Text tag="h2" field={slide.title} />
                <button
                  className="btn btn-collapse"
                  onClick={() => {
                    handleCollapse(!collapsed);
                  }}
                >
                  {collapsed ? plusIcon : minusIcon}
                </button>
              </div>
              <div className="slideshow-carousel__slide-card--content">
                <div className="slideshow-carousel__slide-card--content-inner">
                  <div>
                    <RichText field={slide.description} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Set Render FullCards
  const renderFullCards = <>{isDesktop ? renderFullCardsDesktop : renderFullCardsMobile}</>;

  return <>{variant === 'Cards' ? renderCards : renderFullCards}</>;
};

SlideshowCarouselItem.propTypes = propTypes;

export default SlideshowCarouselItem;

const minusIcon = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="white" />
    <mask
      id="mask0_1618_3018"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M15.75 14.25V8.25H14.25V14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1618_3018)">
      <path d="M15.75 14.25H14.25H8.25V15.75H14.25H15.75H21.75V14.25H15.75Z" fill="white" />
    </g>
  </svg>
);

const plusIcon = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="white" />
    <mask
      id="mask0_517_10254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M15.75 14.25V8.25H14.25V14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_517_10254)">
      <path d="M15.75 14.25H14.25H8.25V15.75H14.25H15.75H21.75V14.25H15.75Z" fill="white" />
    </g>
    <mask
      id="mask1_517_10254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M14.25 14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75V8.25H14.25V14.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_517_10254)">
      <path d="M14.25 14.25V15.75V21.75H15.75V15.75V14.25V8.25H14.25V14.25Z" fill="white" />
    </g>
  </svg>
);
