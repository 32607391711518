import React, { useRef, useState } from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import ModalVideo from 'react-modal-video';
import { shape, object } from 'prop-types';

const propTypes = {
  fields: shape({
    video: object.isRequired,
    title: object.isRequired,
    subtitle: object.isRequired,
    videoModal: object.isRequired,
  }).isRequired,
};

const VideoMiddle = ({ fields }) => {
  const [isOpen, setOpen] = useState(false);
  if (typeof document !== 'undefined') {
    if (isOpen) {
      document.body.classList.add('has-overlay');
    } else {
      document.body.classList.remove('has-overlay');
    }
  }

  return (
    <>
      <section className="video-middle-container">
        <div className="icon-overlay">
          <div className="video-overlay">
            <video loop autoPlay muted preload="auto" playsInline>
              <source src={fields.video.value.href} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="container layout-mobile">
          <div className="video-middle-container__centered">
            <Text
              className="title"
              tag="div"
              field={fields.title}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="750"
              data-aos-duration="1000"
            />
            <Text
              className="desc"
              tag="h2"
              field={fields.subtitle}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="850"
              data-aos-duration="1000"
            />
            <i
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="1000"
              data-aos-duration="1000"
              onClick={() => setOpen(true)}
            >
              {playIconBig}
            </i>
          </div>
        </div>
      </section>

      <ModalVideo
        channel="youtube"
        autoplay
        muted
        isOpen={isOpen}
        videoId={fields.videoModal.value}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

VideoMiddle.propTypes = propTypes;

export default VideoMiddle;

const playIconBig = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="rgba(0,0,0,0)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="49.5" stroke="white" />
    <path d="M75 50L37.5 71.6506L37.5 28.3494L75 50Z" fill="white" />
  </svg>
);
