import React, { useRef } from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { object, shape } from 'prop-types';

const propTypes = {
  fields: shape({
    video: object.isRequired,
    mainIcon: object.isRequired,
    title: object.isRequired,
    subtitle: object.isRequired,
    description: object.isRequired,
    scrollIcon: object.isRequired,
  }),
};

const HeroVideo = ({ fields }) => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="header-main">
      <video loop autoPlay muted preload="auto" playsInline>
        <source src={fields.video.value.href} type="video/mp4" />
      </video>
      <div className="container">
        <div className="content">
          <Image
            className="content__img"
            media={fields.mainIcon}
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="1250"
            data-aos-duration="1000"
          />
          <Text
            className="content__welcome"
            tag="div"
            field={fields.title}
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="750"
            data-aos-duration="1000"
          />
          <Text
            className="content__title"
            tag="h2"
            field={fields.subtitle}
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="850"
            data-aos-duration="1000"
          />
          <div
            className="content__descHero"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="950"
            data-aos-duration="1000"
          >
            <RichText field={fields.description} />
          </div>
        </div>
      </div>
      {/* <img src="../../assets/img/scroll-down-icon.png" className="heroMain__down" /> */}
      <Image className="icon__down" media={fields.scrollIcon} onClick={handleClick} />
      <div ref={ref}></div>
    </section>
  );
};

HeroVideo.propTypes = propTypes;

export default HeroVideo;
