import React, { useState } from 'react';
// import { Text } from '@sitecore-jss/sitecore-jss-react';

import RelatedNewsItem from '../RelatedNewsItem';

const RelatedNews = ({ relatedNews }) => {
  return (
    <div className="latest-news latest-news__related-news">
      <div className="latest-news__wrapper">
        {relatedNews.map((newsItem) => (
          <RelatedNewsItem data={newsItem.fields} key={newsItem.id} url={newsItem.url} />
        ))}
      </div>
    </div>
  );
};

export default RelatedNews;
