import React from 'react';
import { Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object } from 'prop-types';

const propTypes = {
  fields: object.isRequired,
  rendering: object.isRequired,
};

const TwoRowGrid = ({ fields, rendering }) => {
  return (
    <div className="two__row__wrapper">
      <div>
        <Image className="contact-us-bg" media={fields.BackgroundImage} />
        <Placeholder name="top-row" rendering={rendering} />
      </div>
      <div className="container-bleed container-bleed__has-curved-bg min-height-100">
        <Placeholder name="bottom-row" rendering={rendering} />
      </div>
    </div>
  );
};

TwoRowGrid.propTypes = propTypes;

export default TwoRowGrid;
