import React from 'react';
import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { array } from 'prop-types';

const propTypes = {
  curved: array.isRequired,
};

const CurvedWrapper = ({ curved }) => {
  return (
    <div className="container-bleed container-bleed__has-curved-bg">
      <div className="container">{curved}</div>
    </div>
  );
};

CurvedWrapper.propTypes = propTypes;

export default withPlaceholder('curved')(CurvedWrapper);
