import React, { useState, useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
// Import Swiper styles
import '../../assets/sass/components/_swiper.scss';

import useMatchMedia from '../../hooks/useMatchMedia';

import SlideshowCarouselItem from '../SlideshowCarouselItem';
import { object } from 'prop-types';

const propTypes = {
  params: object.isRequired,
  rendering: object.isRequired,
  sitecoreContext: object.isRequired,
};

const SlideshowCarousel = ({ params, rendering, sitecoreContext }) => {
  // matches bp-large-min in breakpoints.scss
  const isDesktop = useMatchMedia('(min-width: 1024px)');
  let variant = 'Full Cards';
  let playSpeed = 10;
  if (typeof params !== 'undefined') {
    variant = params.variant;
    playSpeed = params.playSpeed;
  }
  const [renderSwipers, setRenderSwipers] = useState(false);

  useEffect(() => {
    // required to let text load and prevent swiper init errors
    setRenderSwipers(true);
  }, []);

  const slides = rendering.fields.items.filter((slide) => slide.fields.title.value !== '');
  let slideVariantClass =
    variant === 'Full Cards Flipped'
      ? 'slideshow-carousel--full-card-flipped'
      : variant === 'Full Cards'
      ? 'slideshow-carousel--full-card'
      : 'slideshow-carousel--cards';

  const renderDesktop = (
    <>
      {renderSwipers && (
        <Swiper
          observer={true}
          observeParents={true}
          style={{ '--swiper-pagination-bullet-horizontal-gap': '10px' }}
          className="container--bleed"
          key={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
          dir={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
          spaceBetween={0}
          modules={[Pagination, Autoplay, Navigation]}
          pagination={{
            clickable: 'true',
            type: 'bullets',
            renderBullet: function (index, className) {
              return `<span class="${className}"><i></i><b style="--animation-duration: ${playSpeed}s"></b></span>`;
            },
          }}
          autoplay={{
            delay: playSpeed * 1000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={slides.length > 1}
          slidesPerView={1}
          slidesPerGroup={1}
          navigation={true}
          threshold={'150px'}
          initialSlide={0}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <SlideshowCarouselItem
                slide={slide.fields}
                slideType={
                  typeof slide.fields.highlightsRecord !== 'undefined'
                    ? 'CardedCarouselItem'
                    : 'CardlessCarouselItem'
                }
                variant={variant}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );

  const renderMobile = (
    <>
      {renderSwipers && (
        <Swiper
          observer={true}
          observeParents={true}
          style={{ '--swiper-pagination-bullet-horizontal-gap': '10px' }}
          className="container--bleed"
          key={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
          dir={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
          spaceBetween={0}
          modules={[Pagination, Autoplay, Navigation]}
          pagination={{
            clickable: 'true',
            type: 'bullets',
            renderBullet: function (index, className) {
              return `<span class="${className}"><i></i><b style="--animation-duration: ${playSpeed}s"></b></span>`;
            },
          }}
          autoHeight={true}
          loop={slides.length > 1}
          slidesPerView={1}
          slidesPerGroup={1}
          navigation={true}
          threshold={'150px'}
          initialSlide={0}
        >
          {slides?.map((slide, index) => (
            <SwiperSlide key={index}>
              <SlideshowCarouselItem
                slide={slide.fields}
                slideType={
                  typeof slide.fields.highlightsRecord !== 'undefined'
                    ? 'CardedCarouselItem'
                    : 'CardlessCarouselItem'
                }
                variant={variant}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );

  return (
    <section className={`slideshow-carousel ${slideVariantClass}`}>
      <div className="slideshow-carousel__wrapper">
        <>
          {isDesktop && renderDesktop}
          {!isDesktop && renderMobile}
        </>
      </div>
    </section>
  );
};

SlideshowCarousel.propTypes = propTypes;

export default withSitecoreContext()(SlideshowCarousel);
