import React from 'react';
import { Link } from 'react-router-dom';
import useMatchMedia from '../../hooks/useMatchMedia';
import { Text, RichText, Image, Link as SiteCoreLink } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import SocialLinks from '../SocialLinks';
import { object, shape } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  fields: shape({
    findUsHeading: object.isRequired,
    findUsContent: object.isRequired,
    phone: object.isRequired,
    email: object.isRequired,
    footerLogo: object.isRequired,
    copyrights: object.isRequired,
    privacyPolicyLink: object.isRequired,
    principleOfUseLink: object.isRequired,
  }),
};

const Footer = (props) => {
  const { sitecoreContext, fields } = props;

  // matches bp-large-min in breakpoints.scss
  const isDesktop = useMatchMedia('(min-width: 1024px)');
  const renderDesktop = (
    <div className="container">
      <div className="footer__top">
        <div className="footer__top--find-us">
          <Text tag="h2" field={fields.findUsHeading} />
          <RichText field={fields.findUsContent} />
        </div>

        <div className="footer__top--contact-us">
          <Text tag="h2" field={fields.contactUsHeading} />
          <a href={`tel:${fields.phone.value}`} dir="ltr">
            {fields.phone.value}
          </a>
          <br />
          <a href={`mailto:${fields.email.value}`}>
            <RichText tag="span" field={fields.email} />
          </a>
        </div>

        <div className="footer__logo">
          <Link to={`/${sitecoreContext.language}`}>
            <Image media={fields.footerLogo} />
          </Link>
        </div>
      </div>
      <hr />
      <div className="footer__bottom">
        <div className="footer__copyrights">
          <div>
            <Text field={fields.copyrights} />
          </div>

          <ul className="footer__other-links">
            <li>
              <SiteCoreLink field={fields.privacyPolicyLink} />
            </li>
            <li>
              <SiteCoreLink field={fields.principleOfUseLink} />
            </li>
          </ul>
        </div>

        <div className="footer__social-links">
          <SocialLinks />
        </div>
      </div>
    </div>
  );

  const renderMobile = (
    <div className="container">
      <div className="footer__top">
        <div className="footer__top--find-us">
          <Text tag="h2" field={fields.findUsHeading} />
          <RichText field={fields.findUsContent} />
        </div>

        <div className="footer__top--contact-us">
          <Text tag="h2" field={fields.contactUsHeading} />
          <RichText field={fields.phone} />
          <a href={`mailto:${fields.email.value}`}>
            <RichText tag="span" field={fields.email} />
          </a>

          <div className="footer__social-links">
            <SocialLinks />
          </div>
        </div>
      </div>

      <div className="footer__bottom">
        <div className="footer__copyrights">
          <div>
            <Text field={fields.copyrights} />
          </div>

          <ul className="footer__other-links">
            <li>
              <SiteCoreLink field={fields.privacyPolicyLink} />
            </li>
            <li>
              <SiteCoreLink field={fields.principleOfUseLink} />
            </li>
          </ul>
        </div>
        <div className="footer__logo">
          <Link to={`/${sitecoreContext.language}`}>
            <img src={fields.footerLogo.value.src} alt={fields.footerLogo.value.alt} />
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <footer>{isDesktop ? renderDesktop : renderMobile}</footer>
    </>
  );
};

Footer.propTypes = propTypes;

export default withSitecoreContext()(Footer);
